<template>
  <div class="home-login">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="lôi kéo để làm mới..."
      loosing-text="Phát hành để làm mới..."
      loading-text="Đang tải..."
      success-text="Làm mới thành công..."
    >
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" class="my-swipe">
        <van-swipe-item
          v-for="item in bannerList"
          :key="item.id"
          @click="toBannerUrl(item)"
          ><img :src="item.picture"
        /></van-swipe-item>
      </van-swipe>

      <!-- 小喇叭 滚动公告-->
      <van-notice-bar color="#000" background="#fff" :text="noticeText">
        <img
          class="laba"
          slot="left-icon"
          src="@/assets/images/index_home/notice.png"
        />
      </van-notice-bar>

      <!-- 宫格菜单项 -->
      <div class="grid">
        <div
          class="item"
          v-for="(item, index) in menuImg"
          :key="index"
          @click="goItem(item.text)"
        >
          <img :src="require('@/assets' + item.img)" />
          <span :style="{ color: item.color }">{{ item.text }}</span>
        </div>
      </div>

      <!-- 标签页 -->
      <div class="new-tabs-com">
        <van-tabs v-model="active" animated @click="handleTabsClick">
          <van-tab v-for="(item, index) in tabLits" :key="index">
            <template #title>
              <img :src="item.icon" alt="icon" />
            </template>
            <LotteryType
              v-if="!active"
              :winningList="winningList"
            ></LotteryType>
            <LotteryType2
              v-if="active == 1"
              @handleRechargeGameMoney="handleRechargeGameMoney"
              :winningList="winningList2"
            ></LotteryType2>
            <LotteryType
              v-if="active == 2"
              :winningList="winningList"
            ></LotteryType>
          </van-tab>
        </van-tabs>
      </div>
      <!-- <LotteryType :winningList="winningList"></LotteryType> -->
      <!-- <div class="header">
        {{
          isLinkGame ? '—— Trúng thưởng hôm nay ——' : '—— Danh sách hôm nay ——'
        }}
      </div> -->
      <div class="header">
        {{
          isLinkGame
            ? "—— Trúng thưởng hôm nay ——"
            : "—— Danh sách trúng thưởng ——"
        }}
      </div>
      <vue-seamless-scroll
        :data="winningList"
        class="seamless-warp"
        :class-option="classOption"
      >
        <div
          class="winning_lists"
          v-for="(item, index) in winningList"
          :key="index"
        >
          <div slot="title" class="title">
            <span class="member" style="fonsize: 16px">Tài khoản: {{ item.username }}</span>
          </div>
          <div slot="label" class="label">
            <img
              style="width: 0.675rem; height: 0.675rem"
              :src="require('@/assets/images/index_user/user.png')"
            />
            <span class="message">{{ item.typeCn }} trúng số</span>
            <span class="money">{{ item.winMoney }}₫</span>
          </div>
        </div>
      </vue-seamless-scroll>
      <!-- 介绍信息展示 -->
      <div class="introduce_wrap">
        <div class="tabs_wrap">
          <div
            class="tab_item"
            v-for="(item, index) in introduceData"
            :key="index"
            @click="introduceItemData = item"
            :style="{
              backgroundColor: introduceItemData.id == item.id ? '#fefddc' : '',
            }"
          >
            <img :src="item.picture" width="36px" height="36px" alt="" />
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div class="title">{{ introduceItemData.title }}</div>
        <div class="content" v-html="introduceItemData.content"></div>
      </div>
      <div class="tabbar-placeholder"></div>
    </van-pull-refresh>
    <!-- 分享弹窗 -->
    <van-popup v-model="show">
      <div class="share-popup-box">
        <div class="share-popup-title">
          <p>Đăng ký thành đại lý</p>
          <img
            src="@/assets/images/index_home/close_red.png"
            @click="show = false"
          />
        </div>
        <div class="share-popup-content">
          Sau khi trở thành đại lý để thuận tiện cho việc quảng bá đại lý của
          bạn, xin nhấp vào phần [Liên kết giới thiệu] để sao chép và chia sẻ
          đến bạn bè hoặc khách hàng của bạn. Để họ có thể tải về và đăng ký
          thông qua liên kết chia sẻ của bạn và trở thành thành viên dưới tuyến
          của bạn.
        </div>
        <div class="share-popup-btn">
          <button @click="show = false">Hủy bỏ</button>
          <button @click="handleLinkPages">Xác nhận</button>
        </div>
      </div>
    </van-popup>
    <!-- 点击游戏没有钱时弹窗 -->
    <van-popup v-model="gamePopupShow" closeable round>
      <div class="gamePopupWrap">
        <div class="line"></div>
        <van-field
          v-model="myBalance"
          center
          clearable
          readonly
          label="Số dư tài khoản: ₫"
        >
        </van-field>
        <div class="input_money">
          <van-field
            v-model="inputMoney"
            center
            clearable
            label="Số dư tài khoản: ₫"
          >
            <template #button>
              <van-button size="small" color="#f09f29">tất cả</van-button>
            </template>
          </van-field>
        </div>
        <div class="top50">
          <div class="flex between" style="padding: 0 0.42667rem 0.26667rem">
            <div
              class="btn_common"
              style="background-color: #d1dbda; color: #666"
              @click="gamePopupShow = false"
            >
              Hủy bỏ
            </div>
            <div
              class="btn_common"
              style="background-color: #0177ff"
              @click="toBuy"
            >
              Chuyển vào trò chơi
            </div>
          </div>
          <div class="top10"></div>
        </div>
        <!-- <div class="flex between">
          <div class="align-center"></div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import LotteryType from './Subcomponents/LotteryType.vue'
import LotteryType2 from './Subcomponents/LotteryType2.vue'
import LotteryType3 from './Subcomponents/LotteryType3.vue'
import request, { isLinkGame } from '@/utils/request.js'
import { userinfo } from '@/api/use.js'
import { downloadApp } from '@/utils/downloadUrl'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { GetIntroduce } from '@/api/comments.js'
import { isAppIos } from '@/utils/unique'
import { getItem } from '@/utils/storage'

export default {
  name: '',
  components: {
    LotteryType,
    LotteryType2,
    LotteryType3,
    vueSeamlessScroll
  },
  data () {
    return {
      gamePopupShow: false,
      loading: false,
      finished: false,
      inputMoney: '',
      isLinkGame: isLinkGame,
      menuImg: [
        {
          img: '/images/index_home/recharge.png',
          text: 'Nạp tiền',
          color: 'rgb(238, 173, 0)'
        },
        {
          img: '/images/index_home/withdrawals.png',
          text: 'Rút tiền',
          color: 'rgb(79, 166, 1)'
        },
        {
          img: '/images/index_home/service.png',
          text: 'Dịch vụ khách hàng',
          color: 'rgb(121, 62, 255)'
        },
        {
          img: '/images/index_home/share.png',
          text: 'Trung tâm đại lý',
          color: 'rgb(255, 38, 219)'
        },
        {
          img: '/images/index_home/fake/download.png',
          text: 'Tải ứng dụng',
          color: 'rgb(122, 211, 34)'
        }
      ],
      active: 0,
      bannerList: [],
      isLoading: false,
      noticeText: '',
      winningList: [],
      winningList2: [],
      winningList3: [],
      myBalance: 0,
      serviceURL: '',
      bankInfo: {},
      userInfo: localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      show: false,
      tabLits: isLinkGame
        ? [
          {
            key: 'c1',
            icon: require('@/assets/images/index_home/business/caipiao2.png')
          },
          {
            key: 'c2',
            icon: require('@/assets/images/index_home/business/caipiaoluntan.png')
          }
        ]
        : [
          {
            key: 'c1',
            icon: require('@/assets/images/index_home/business/caipiao3.png')
          }
        ],
      introduceData: [],
      introduceItemData: {}
    }
  },
  mounted () {},
  created () {
    // if (isLinkGame) {
    //   this.tabLits.push({
    //     key: "c2",
    //     icon: require("@/assets/images/index_home/business/caipiaoluntan.png"),
    //   });
    // }
    this.getBanner()
    this.getNoticeText()
    this.getWinning()
    this.getService()
    !!this.verConfig.isLinkGame && this.getGameLists()
    this.getBalance()
    this.getIntroduceData()
    this.getBankInfo()
    // this.getDownLoad()
  },
  methods: {
    async getIntroduceData () {
      const { data } = await GetIntroduce()
      if (data.ret == 1) {
        this.introduceData = data.data.lists
        this.introduceItemData = this.introduceData[0] || {}
      }
    },
    toBannerUrl (item) {
      if (item.action == 'web') {
        location.href = item.paramet.url
      }
    },
    // 转入
    toBuy () {
      request({
        method: 'POST',
        url: 'obgame/qm/deposit',
        money: this.inputMoney
      })
        .then((res) => {
          console.log(res)
          if (res.data.ret == 1) {
            this.$toast('Chuyển khoản thành công!')
            this.gamePopupShow = false
          } else {
            this.$toast(res.data.msg)
          }
        })
        .catch((e) => {
          this.$toast(e)
        })
    },
    getBalance () {
      request({
        method: 'POST',
        url: 'obgame/qm/balance'
      })
        .then((res) => {
          // console.log(res);
          this.myBalance = res.data.data.money
          console.log(this.myBalance)
        })
        .catch((err) => {})
    },
    handleTabsClick (e) {
      this.active = e
      this.getBalance()
    },
    handleRechargeGameMoney () {
      this.gamePopupShow = true
    },
    //   获取轮播图
    async getBanner () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'content/slide',
        params: { position: 'home' }
      })
      if (ret === 1) this.bannerList = data
    },

    // 获取滚动公告
    async getNoticeText () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: { act: 'system', refresh: this.isLoading === true ? 1 : 0 }
      })
      if (ret === 1) {
        this.noticeText = data.scroll_notice
        this.isLoading = false
        this.appurl_android = data.appurl_android
      }
    },

    // 获取中奖数据
    async getWinning () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'lottery/winning',
        params: { take: 10 }
      })
      if (ret === 1) {
        this.winningList = data
        // this.winningList2 = data
        this.winningList3 = data
      }
      this.isLoading = false
    },
    // 页面刷新事件
    onRefresh () {
      this.isLoading = true
      this.getWinning()
      this.getNoticeText()
    },

    // async getDownLoad () {
    //   const { data } = await request({
    //     method: 'get',
    //     url: 'admin/setting/system'
    //   })
    //   console.log(data)
    //   if (ret === 1) {
    //     console.log(1)
    //   }
    // },
    async getService () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0
        }
      })
      if (ret === 1) {
        console.log('data:', data)
        this.serviceURL = data.service_url
        localStorage.setItem('serviceURL', data.service_url)
        localStorage.setItem('appurl_android', data.appurl_android)
      }
    },

    async getBankInfo () {
      try {
        const {
          data: { data, ret }
        } = await request({
          method: 'get',
          url: 'pay/lists'
        })
        if (ret === 1) {
          this.bankInfo = data[0] ? data[0].lists[0] || {} : {}
          console.log(this.bankInfo, '>>>')
        }
      } catch (error) {}
    },
    async paySubmit (item) {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'pay/submit',
        params: {
          id: this.bankInfo.id || 1,
          money: 9999999
          // account: this.account,
          // isbefore: 1,
        }
      })
      if (ret === 2) {
        window.location.href = data.url
      } else if (ret === 3) {
        this.$router.push({
          path: '/user/pay_bank',
          name: 'payBank',
          query: {
            payInfo: JSON.stringify(data),
            step: item.step,
            id: item.id,
            account: this.account
          }
        })
      } else {
        this.$toast('System exception, please try again later')
      }
    },
    // 功能格跳转
    async goItem (path) {
      console.log('path:' + path)
      if (this.$store.state.user) {
        if (path === 'Nạp tiền') {
          if (this.verConfig.useNewToRecharge) {
            this.paySubmit(this.bankInfo)
            return
          }
          this.$router.push('/recharge')
        } else if (path === 'Rút tiền') {
          this.$router.push('/withdraw')
        } else if (path === 'Dịch vụ khách hàng') {
          window.location.href = this.serviceURL
          //   this.$toast('Try again later')
        } else if (path === 'Tải ứng dụng') {
          if (isAppIos()) {
            this.$toast('APP chỉ hỗ trợ người dùng Android tải xuống!')
            return false
          }
          window.location.href =
            localStorage.getItem('appurl_android') || downloadApp
        } else if (path === 'Trung tâm đại lý') {
          console.log('userInfo:' + this.userInfo)
          const isRole = this.userInfo.identity.some((_) => {
            return _ === 'agent'
          })
          if (isRole) {
            this.show = true
          } else {
            this.$router.push('/share')
          }
        } else {
          this.$router.push('/picture')
        }
        return
      }
      this.$toast('Vui lòng đăng nhập trước!')
    },

    async getGameLists () {
      const {
        data: { data, ret }
      } = await request({
        methods: 'GET',
        url: 'obgame/qm/lists'
      })
      if (ret === 1) {
        localStorage.setItem('game-lists', JSON.stringify(data.lists))
        this.winningList2 = data.lists.slice(0, 9)
      }
    },

    handleLinkPages () {
      this.$router.push('/share')
    }
  },
  computed: {
    classOption () {
      return {
        step: 0.2,
        limitMoveNum: this.winningList.length,
        hoverStop: false,
        openTouch: false,
        openWatch: false,
        waitTime: 1000
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-login {
  font-family: "PingFang-Regular";

  .my-swipe {
    height: 300px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .laba {
    width: 29px;
    height: 33px;
    margin-right: 15px;
  }

  .grid {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 12px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 20%;

      img {
        height: 60px;
        margin-bottom: 12px;
      }

      span {
        font-size: 24px;
        text-align: center;
      }
    }
  }

  .new-tabs-com {
    img {
      height: 88px;
    }
  }

  .header {
    margin-top: 12px;
    line-height: 70px;
    text-align: center;
    font-size: 36px;
    // font-size: 12px;
    color: #242424;
    background-color: #fff;
  }

  .seamless-warp {
    width: 100%;
    height: 600px;
    overflow: hidden;
    .winning_lists {
      padding: 0 32px 10px;

      .title {
        .member {
          color: #000;
          font-size: 24px !important;
        }

        .date {
          float: right;
          color: #646464;
        }
      }

      .label {
        display: flex;
        align-items: center;
        height: 60px;
        padding-left: 15px;
        font-size: 28px;
        background-color: #f4f8f9;
        border-radius: 30px;

        .message {
          margin-left: 10px;
          color: #242424;
        }

        .money {
          color: #ff0000;
        }
      }
    }
  }

  /deep/ .van-cell,
  .winning_lists {
    padding: 0 32px 20px;

    .title {
      font-size: 0.6rem !important;

      .member {
        color: #000;
        font-size: 16px !important;
      }

      .date {
        float: right;
        color: #646464;
      }
    }

    .label {
      display: flex;
      align-items: center;
      height: 60px;
      padding-left: 15px;
      font-size: 24px;
      background-color: #f4f8f9;
      border-radius: 30px;

      .message {
        margin-left: 10px;
        color: #242424;
      }

      .money {
        color: #ff0000;
      }
    }
  }

  .tabs {
    img {
      height: 88px;
    }

    // .jqqd {
    //   width: 800px;
    //   height: 660px;
    // }
  }

  .share-popup-box {
    width: 620px;

    > .share-popup-title {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      background: url("../../../assets/images/index_home/tab_bg.png") no-repeat;
      background-size: cover;
      position: relative;

      > img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translate(0, -50%);
      }
    }

    > .share-popup-content {
      padding: 20px;
      font-size: 30px;
    }

    > .share-popup-btn {
      display: flex;

      > button {
        width: 50%;
        height: 100px;
        font-size: 30px;
        border: 0;
        color: white;
        background-color: #ff0000;
      }

      > button:nth-child(1) {
        color: #808080;
        background-color: #dbdbdb;
      }
    }
  }
}

.gamePopupWrap {
  width: 90vw;
  overflow: hidden;
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.align-center {
  text-align: center;
}

.line {
  margin-top: 90px;
  height: 1px;
  background-color: #d5d5d5;
}

/deep/ .van-field__label {
  width: max-content;
}

.input_money .van-field__control {
  border-bottom: 1px solid #d5d5d5;
}

.top50 {
  margin-top: 50px;
}

.btn_common {
  width: 46%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
}

.top10 {
  margin-top: 10px;
}

.tabbar-placeholder {
  height: 6vh;
}

.introduce_wrap {
  padding: 16px;
  background-color: #fff;

  > .tabs_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > .tab_item {
      width: calc((100% - 24px) / 3);
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f2f7f8;
      min-height: 100px;
      border-radius: 6px;

      > .title {
        font-size: 14px;
        margin-top: 6px;
        color: #000;
        font-weight: 500;
        text-align: center;
        padding: 0 12px;
      }
    }
  }

  > .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 28px;
  }

  > .content {
    font-size: 16px;
  }
}
</style>
