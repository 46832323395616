<template>
  <div>
    <!-- 登录组件 -->
    <!-- <HomeLogin v-if="$store.state.user"/> -->
    <HomeLogin />
    <!-- 未登录组件 -->
    <!-- <HomeLogout v-else/> -->
  </div>
</template>

<script>
import HomeLogout from './components/HomeLogout.vue'
import HomeLogin from './components/HomeLogin.vue'
export default {
  name: '',
  components: {
    HomeLogout,
    HomeLogin,
  },
  data() {
    return {}
  },
  mounted() {},
  created() {
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
