<template>
  <!-- 官方彩票 -->
  <div class="lottery">
    <van-grid direction="horizontal" :column-num="columnNum">
      <van-grid-item
        v-for="(item, index) in lotteryList"
        :key="index"
        :icon="item.picture"
        @click="goToOrder(item)"
      >
        <div slot="text" class="words_right">
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.subtitle }}</div>
        </div>
      </van-grid-item>
    </van-grid>
    <!-- <div class="newDiv" @click="goToOrder(lotteryList[0])"> -->
      <!-- <img :src="require('@/assets/images/home_icon.png')" alt=""> -->
      <!-- <img :src="lotteryList[0].picture" alt="">
    </div> -->

    <!-- 会员列表 -->
    <!-- <div class="header">—— Trúng thưởng hôm nay ——</div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="không còn nữa..."
      @load="onLoad"
      loading-text="Đang tải..."
    >
      <van-cell v-for="(item, index) in winningList" :key="index">
        <div slot="title" class="title">
          <span class="member">Người dùng:{{ item.username }}</span>
          <span class="date">{{item.forum_date}}</span>
        </div>
        <div slot="label" class="label">
          <van-image
            round
            width=".675rem"
            height=".675rem"
            :src="require('@/assets/images/index_user/user.png')"
          />
          <span class="message">{{ item.typeCn }} trúng số</span>
          <span class="money">₫{{ item.winMoney }}</span>
        </div>
      </van-cell>
    </van-list> -->
  </div>
</template>

<script>
import request from '@/utils/request.js'
import { Dialog } from 'vant'
import { downloadApp } from '@/utils/downloadUrl'
import { isAppIos } from '@/utils/unique'
export default {
  name: '',
  components: {},
  props: {
    winningList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      lotteryList: [],
      list: [],
      loading: false,
      finished: false,
      userInfo: localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      columnNum: 4,
    }
  },
  mounted() {},
  created() {
    this.lotteryList = localStorage.getItem('game-old-lists')
      ? JSON.parse(localStorage.getItem('game-old-lists'))
      : []
    this.getLottery()
  },
  methods: {
    // 获取才彩票数据
    async getLottery() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'lottery/setting',
      })
      if (ret === 1) {
        this.lotteryList = data
        localStorage.setItem('game-old-lists', JSON.stringify(data))
        if (this.lotteryList.length && this.lotteryList.length < 4) {
          this.columnNum = this.lotteryList.length
        } else {
          this.columnNum = 4
        }
      } else {
        this.lotteryList = localStorage.getItem('game-old-lists')
          ? JSON.parse(localStorage.getItem('game-old-lists'))
          : []
      }
    },
    goToOrder(data) {
      if(this.verConfig.homeGameOnlyShowPicture) return;
      if (!this.isGameGoLink(data)) {
        this.$toast(
          `bạn phải nạp tiền ${data.minamount} đồng trước khi bạn có thể mở trò chơi này.`
        )
        return false
      }
      let list = ['sfssc', 'txffc', 'sfpk10', 'xyft', 'jnd28', 'xy28']
      let list2 = ['sf3d', 'ffk3', 'sf11x5', 'hk6hc', 'js6hc', 'cqssc', 'bjsc']
      list = list.concat(list2)
      const isGo = list.some((_) => {
        return _ === data.type
      })
      if (data.type === 'hl' || data.type.indexOf('hl') !== -1) {
        this.$store.commit('setLottery', data)
        this.$router.push('/user/order')
        return false
      } else if (isGo) {
        this.$store.commit('setLottery', data)
        this.$router.push('/user/order2')
        return false
      } else {
        Dialog.confirm({
          title: 'dấu hiệu',
          message:
            'Phiên bản web không có chức năng này. Vui lòng chuyển đến phiên bản ứng dụng tải xuống.',
          cancelButtonText: 'Hủy bỏ',
          confirmButtonText: 'xác nhận',
        })
          .then(() => {
            if (isAppIos()) {
              this.$toast('APP chỉ hỗ trợ người dùng Android tải xuống!')
              return false
            }
            window.location.href =
              localStorage.getItem('appurl_android') || downloadApp
          })
          .catch(() => {
            // on cancel
          })
        return false
      }
    },
    isGameGoLink(item) {
      let employMoney = 0

      if (this.userInfo && Object.keys(this.userInfo).length) {
        employMoney = this.userInfo.money_plus
      } else {
        employMoney = 0
      }
      if (employMoney >= Number(item.minamount)) {
        return true
      } else {
        return false
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    },
  },
}
</script>

<style lang="less" scoped>
.lottery {
  font-family: 'PingFang-Regular';
  /deep/ .van-icon__image {
    width: 106px;
    height: 106px;
  }
  /deep/ .van-grid-item__content--horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /deep/ .van-grid-item__content {
    padding: 20px 0;
  }
  // .newDiv{
  //   margin: 8px 20px;
  //   margin-top: 30px;
  //   > img{
  //     border-radius: 8px;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  .words_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin: 5px 0;
    }
    .time {
      font-size: 12px;
    }
  }

  .header {
    margin-top: 12px;
    line-height: 70px;
    text-align: center;
    font-size: 36px;
    color: #242424;
    background-color: #fff;
  }
  /deep/ .van-cell {
    padding: 0 32px 20px;
    .title {
      font-size: 20px;

      .member {
        color: #000;
      }
      .date {
        float: right;
        color: #646464;
      }
    }
    .label {
      display: flex;
      align-items: center;
      height: 60px;
      padding-left: 15px;
      font-size: 24px;
      background-color: #f4f8f9;
      border-radius: 30px;
      .message {
        margin-left: 10px;
        color: #242424;
      }
      .money {
        color: #ff0000;
      }
    }
  }
}
</style>
